import merge from 'lodash/fp/merge'
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import { useEffectOnce } from 'react-use'

import { parsedTranslations } from '../utils/ConfigModeUtils'
import { getCurrentLanguage } from '../utils/LanguageUtils'
import { isConfigMode } from '../utils/StyleUtils'

import PortalConfigContext from './PortalConfigContext'

export const DELETED_TRANSLATION = 'deleted_translation'
export const PERSISTED_TRANSLATION_OVERRIDES =
  'persisted_translation_overrides_in_config_mode'

enum TRANSLATION_EVENTS {
  REMOVE_PERSISTED_TRANSLATIONS_EVENT = 'REMOVE_PERSISTED_TRANSLATIONS_EVENT'
}

export interface TranslationContextState {
  data: TranslationContextData
  actions: TranslationContextActions
}

export interface TranslationContextData {
  overrides: any
}

export interface TranslationContextActions {
  updateTranslation: (updatePath: string, value: string) => void
  updateBulkTranslation: (value: unknown) => void
  removeTranslation: (removePath: string) => void
  permanentRemoveTranslations: (translationKeys: string[]) => void
  resetTranslation: (resetPath: string) => void
}

const defaultState = {
  data: null,
  actions: {
    updateTranslation: () => null,
    updateBulkTranslation: () => null,
    removeTranslation: () => null,
    resetTranslation: () => null,
    permanentRemoveTranslations: () => null
  }
}

const TranslationContext = createContext<TranslationContextState>(defaultState)

export interface TranslationProviderProps {
  children: ReactNode
}

const TranslationProvider = ({ children }: TranslationProviderProps) => {
  const { data: portalConfigData, actions: portalConfigActions } =
    useContext(PortalConfigContext)

  const [overrides, setOverrides] = useState<any>({})

  // Normal mode initialization
  useEffect(() => {
    if (!isConfigMode()) {
      const translationOverrides =
        portalConfigData.portalConfig?.config?.translationOverrides

      if (translationOverrides) {
        setOverrides(translationOverrides)
      }
    }
  }, [portalConfigData])

  const updateTranslation = useCallback(
    (updatePath: string, value: string) => {
      const newOverrides = merge(overrides, {
        [getCurrentLanguage()]: { [updatePath]: value }
      })

      setOverrides(newOverrides)

      const persistedTranslationOverridesLS = parsedTranslations(
        localStorage.getItem(PERSISTED_TRANSLATION_OVERRIDES)
      )

      // Persist translations on change
      localStorage.setItem(
        PERSISTED_TRANSLATION_OVERRIDES,
        JSON.stringify(merge(persistedTranslationOverridesLS, newOverrides))
      )

      window.parent.postMessage(
        { type: 'translation_override', data: newOverrides },
        '*'
      )
    },
    [overrides]
  )

  // Remove persisted translation when user leave portal preview
  useEffectOnce(() => {
    const getWidgetsEventHandler = (event: MessageEvent) => {
      if (
        event.data.type ===
        TRANSLATION_EVENTS.REMOVE_PERSISTED_TRANSLATIONS_EVENT
      ) {
        localStorage.removeItem(PERSISTED_TRANSLATION_OVERRIDES)
      }
    }

    window.addEventListener('message', getWidgetsEventHandler)

    return () => {
      window.removeEventListener('message', getWidgetsEventHandler)
    }
  })

  const updateBulkTranslation = useCallback(
    (updatedValue: unknown) => {
      const newOverrides = merge(overrides, {
        [getCurrentLanguage()]: updatedValue
      })

      setOverrides(newOverrides)

      window.parent.postMessage(
        { type: 'translation_override', data: newOverrides },
        '*'
      )
    },
    [overrides]
  )

  const removeTranslation = useCallback(
    (removePath: string): void => {
      const newOverrides = merge(overrides, {
        [getCurrentLanguage()]: { [removePath]: DELETED_TRANSLATION }
      })

      setOverrides(newOverrides)

      window.parent.postMessage(
        {
          type: 'translation_delete',
          data: {
            [getCurrentLanguage()]: { [removePath]: DELETED_TRANSLATION }
          }
        },
        '*'
      )
    },
    [overrides]
  )

  const permanentRemoveTranslations = useCallback(
    (translationKeys: string[]): void => {
      const cloneNewAddedTranslations = { ...overrides?.[getCurrentLanguage()] }
      const cloneExistingTranslations = {
        ...portalConfigData.portalConfig?.config?.translationOverrides?.[
          getCurrentLanguage()
        ]
      }

      const isNewlyAddedBullet = translationKeys.some((key) =>
        Object.keys(cloneNewAddedTranslations || {}).includes(key)
      )

      const isExistingBullet = translationKeys.some((key) =>
        Object.keys(cloneExistingTranslations || {}).includes(key)
      )

      if (isNewlyAddedBullet) {
        translationKeys.forEach((key) => {
          delete cloneNewAddedTranslations[key]
        })

        setOverrides({ [getCurrentLanguage()]: cloneNewAddedTranslations })
      } else if (isExistingBullet) {
        translationKeys.forEach((key) => {
          delete cloneExistingTranslations[key]
        })

        portalConfigActions.updateDataAtPath(
          `config.translationOverrides.${getCurrentLanguage()}`,
          cloneExistingTranslations
        )
      }

      const updatedTranslationOverrides = {
        ...cloneNewAddedTranslations,
        ...cloneExistingTranslations
      }

      window.parent.postMessage(
        {
          type: 'translation_permanent_delete',
          data: {
            locale: getCurrentLanguage(),
            data: updatedTranslationOverrides
          }
        },
        '*'
      )
    },
    [
      overrides,
      portalConfigActions,
      portalConfigData.portalConfig?.config?.translationOverrides
    ]
  )

  const resetTranslation = useCallback(
    (resetPath: string) => {
      const clonedOverride = { ...overrides }

      delete clonedOverride[getCurrentLanguage()]?.[resetPath]

      setOverrides(clonedOverride)
    },
    [overrides]
  )

  return (
    <TranslationContext.Provider
      value={{
        data: {
          overrides
        },
        actions: {
          updateTranslation,
          updateBulkTranslation,
          removeTranslation,
          permanentRemoveTranslations,
          resetTranslation
        }
      }}
    >
      {children}
    </TranslationContext.Provider>
  )
}

export { TranslationProvider, TranslationContext }
