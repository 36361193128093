import {
  DefaultEpilotTheme,
  EpilotOverrides,
  EpilotTheme
} from '@epilot/base-elements'
import { type ClassValue, clsx } from 'clsx'
import _ from 'lodash/fp'
import { twMerge } from 'tailwind-merge'

import { QUERY_PARAM } from '../hooks/common/useQueryParams'

export const CLASS_PREFIX = 'ecp'
export const MAX_BORDER_RADIUS = 20

export const BREAK_POINTS = {
  mobileS: 320,
  mobileM: 375,
  mobileL: 425,
  tablet: 768,
  laptopS: 1024,
  laptopM: 1232,
  laptopL: 1440,
  desktopS: 1920,
  desktopM: 2560
}

export const MEDIA_SIZES = {
  lower: {
    mobileS: `(max-width: ${BREAK_POINTS.mobileS}px)`,
    mobileM: `(max-width: ${BREAK_POINTS.mobileM}px)`,
    mobileL: `(max-width: ${BREAK_POINTS.mobileL}px)`,
    tablet: `(max-width: ${BREAK_POINTS.tablet}px)`,
    laptopS: `(max-width: ${BREAK_POINTS.laptopS}px)`,
    laptopM: `(max-width: ${BREAK_POINTS.laptopM}px)`,
    laptopL: `(max-width: ${BREAK_POINTS.laptopL}px)`,
    desktopS: `(max-width: ${BREAK_POINTS.desktopS}px)`,
    desktopM: `(max-width: ${BREAK_POINTS.desktopM}px)`
  },

  greater: {
    mobileS: `(min-width: ${BREAK_POINTS.mobileS}px)`,
    mobileM: `(min-width: ${BREAK_POINTS.mobileM}px)`,
    mobileL: `(min-width: ${BREAK_POINTS.mobileL}px)`,
    tablet: `(min-width: ${BREAK_POINTS.tablet}px)`,
    laptopS: `(min-width: ${BREAK_POINTS.laptopS}px)`,
    laptopM: `(min-width: ${BREAK_POINTS.laptopM}px)`,
    laptopL: `(min-width: ${BREAK_POINTS.laptopL}px)`,
    desktopS: `(min-width: ${BREAK_POINTS.desktopS}px)`,
    desktopM: `(max-width: ${BREAK_POINTS.desktopM}px)`
  }
}

export const DEFAULT_ECP_THEME: Required<EpilotTheme> = _.merge(
  DefaultEpilotTheme,
  {
    palette: { primary: { main: '#0F5B99' }, secondary: { main: '#428ECC' } }
  }
) as Required<EpilotTheme>

// check is preview mode in "Design Builder"
export const isPreviewMode = (): boolean => {
  return (
    Boolean(
      new URLSearchParams(window.location.search).get(QUERY_PARAM.preview)
    ) || isConfigMode()
  )
}

// check config mode in "ECP Settings"
export const isConfigMode = (): boolean => {
  return Boolean(
    new URLSearchParams(window.location.search).get(QUERY_PARAM.orgId)
  )
}

export const isNormalMode = (): boolean => {
  return !isConfigMode() && !isPreviewMode()
}

export const mapToCorrectTheme = (
  portalTheme: EpilotTheme,
  hashedTheme?: string
): EpilotTheme => {
  let mergedWithECPTheme: EpilotTheme = _.merge(DEFAULT_ECP_THEME, portalTheme)

  if (hashedTheme) {
    let theme: EpilotTheme

    try {
      const previewTheme = window.atob(hashedTheme)

      theme = JSON.parse(previewTheme)
    } catch (error) {
      theme = portalTheme
    }

    mergedWithECPTheme = _.merge(DEFAULT_ECP_THEME, theme)
  }

  const overrides: EpilotOverrides = { ...mergedWithECPTheme.muiOverrides }

  delete overrides.MuiInputBase

  const themeWithGoodOverrides: EpilotTheme = {
    ...mergedWithECPTheme,
    muiOverrides: overrides
  }

  return themeWithGoodOverrides
}

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}
