import { Auth } from '@aws-amplify/auth'
import { Amplify } from '@aws-amplify/core'

import { PortalConfig } from 'context/PortalConfigContext'

import { ENV_CONFIG } from '../EnvConfig'

const ORG_ID_LS_KEY = 'ORG_ID_LS_KEY'
const TOKEN_LS_KEY = 'token'

let isConfigured = false

const configure = ({
  orgId,
  cognitoDetails
}: {
  orgId: string
  cognitoDetails: PortalConfig['cognito_details']
}) => {
  if (!isConfigured) {
    Amplify.configure({
      authenticationFlowType: 'USER_SRP_AUTH',
      region: ENV_CONFIG.AMPLIFY_REGION,
      userPoolId: cognitoDetails.cognito_user_pool_id,
      userPoolWebClientId: cognitoDetails.cognito_user_pool_client_id
    })

    persistOrgId(orgId)

    window.EndUserPortal = {
      getToken: getAccessToken,
      getOrgId
    }

    isConfigured = true
  }
}

const signOut = () => {
  setUserToken(null)

  return Auth.signOut()
}

const getSession = async () => {
  if (!isConfigured) return null

  try {
    return await Auth.currentSession()
  } catch (err) {
    return null
  }
}

const getRefreshToken = async (): Promise<string | null> => {
  try {
    const session = await getSession()

    return session.getRefreshToken()?.getToken()
  } catch (err) {
    return null
  }
}

const getAccessToken = async (): Promise<string | null> => {
  try {
    const session = await getSession()

    const idtoken = session?.getIdToken()

    setUserToken(idtoken.getJwtToken())

    return idtoken.getJwtToken()
  } catch (err) {
    return null
  }
}

const setUserToken = (token: string | null): void => {
  if (token) {
    localStorage.setItem(TOKEN_LS_KEY, token)
  } else {
    localStorage.removeItem(TOKEN_LS_KEY)
  }
}

const getOrgId = (): string => {
  return localStorage.getItem(ORG_ID_LS_KEY)
}

const persistOrgId = (portalId: string): void => {
  localStorage.setItem(ORG_ID_LS_KEY, portalId)
}

export default {
  configure,
  signOut,
  getRefreshToken,
  getAccessToken,
  getSession,
  getOrgId
}
