import i18next from 'i18next'
import { useContext } from 'react'

import PortalConfigContext, {
  AvailableLanguage
} from '../context/PortalConfigContext'
import { changeLanguage } from '../i18n/config'

import { isConfigMode } from './StyleUtils'

export enum Locale {
  DE = 'de',
  EN = 'en'
}

export interface Language {
  key: AvailableLanguage
  name: string
  locale: Locale
}

export const ALL_LANGUAGES: Language[] = [
  { key: 'english', name: 'languages.english', locale: Locale.EN },
  { key: 'german', name: 'languages.german', locale: Locale.DE }
]

export const setLanguage = (availableLanguages: AvailableLanguage[]): void => {
  const hasOnlyOneAvailableLanguage = availableLanguages?.length === 1
  const thereIsNoAvailableLanguage = !availableLanguages?.length

  if (thereIsNoAvailableLanguage) {
    changeLanguage(Locale.DE)

    return
  }

  if (hasOnlyOneAvailableLanguage) {
    const selectedLanguage = ALL_LANGUAGES.find(
      (language) => language.key === availableLanguages[0]
    )

    changeLanguage(selectedLanguage.locale)
  }
}

export const useAllAvailableLanguages = (): Language[] => {
  const { data: portalConfigData } = useContext(PortalConfigContext)

  return ALL_LANGUAGES.filter((lang) =>
    portalConfigData.portalConfig.config?.available_languages?.includes(
      lang.key
    )
  )
}

export const getCurrentLanguage = (): Locale => {
  if (isConfigMode()) {
    const selectedLanguage = new URLSearchParams(window.location.search).get(
      'language'
    ) as Locale

    return selectedLanguage?.toUpperCase() in Locale
      ? selectedLanguage
      : Locale.DE
  }

  return i18next.language || window.localStorage.i18nextLng
}

export const applySelectedLanguageInConfigMode = (): void => {
  if (isConfigMode()) {
    const selectedLanguage: Locale = getCurrentLanguage()

    i18next.changeLanguage(selectedLanguage)
  }
}

export const checkContactLanguageIsAvailable = (
  selectedLocale: Locale,
  availableLanguages: AvailableLanguage[]
): boolean => {
  const selectedLanguage: Language = ALL_LANGUAGES.find(
    (lang) => lang.locale === selectedLocale
  )

  return availableLanguages?.includes(selectedLanguage.key)
}

export const convertToLocaleNumber = (value: number): string => {
  return new Intl.NumberFormat(getCurrentLanguage()).format(value)
}

export const getTranslatedProperty = (source: unknown) => {
  if (source === null || typeof source !== 'object') return undefined

  const currentLang = getCurrentLanguage()

  if (source[currentLang]) return source[currentLang]

  // Default to English
  if (source[Locale.EN]) return source[Locale.EN]

  // Or the first available language
  const availableLanguages = Object.keys(source)

  if (availableLanguages.length) return source[availableLanguages[0]]

  return undefined
}
