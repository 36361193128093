export const MOCK_CONTACT_SCHEMA = {
  id: 'ab5df39c-71e4-4ec0-b59b-9c3891ac4bb9',
  name: 'Contact',
  plural: 'Contacts',
  slug: 'contact',
  icon: 'person',
  published: true,
  title_template: '{{first_name}} {{last_name}}',
  ui_config: {
    table_view: {
      view_type: 'default',
      dropdown_items: [
        {
          type: 'entity',
          entity: 'contact'
        },
        {
          type: 'entity',
          entity: 'account'
        },
        {
          type: 'entity',
          entity: 'portal_user',
          feature_flag: 'ff_ecp_settings_enabled'
        }
      ],
      row_actions: ['view', 'edit', 'share', 'delete'],
      classic_view:
        '/app/ivy/$/ivy/pro/Portal/epilot_cloud/15FDE488211714BF/customer.ivp'
    },
    create_view: {
      view_type: 'default'
    },
    edit_view: {
      view_type: 'default'
    },
    list_item: {
      summary_attributes: [
        'salutation',
        'phone',
        'email',
        'address',
        'account',
        'customer_number'
      ]
    },
    sharing: {
      show_sharing_button: true
    }
  },
  group_settings: [
    {
      id: 'Personal Details',
      label: 'Personal Details',
      expanded: true,
      order: 10,
      _purpose: []
    },
    {
      id: 'Contact Details',
      label: 'Contact Details',
      expanded: true,
      order: 20
    },
    {
      id: 'Company Details',
      label: 'Company Details',
      expanded: true,
      order: 30
    },
    {
      id: 'Address Details',
      label: 'Address Details',
      expanded: true,
      order: 50
    },
    {
      id: 'Marketing Consent Management',
      label: 'Marketing Consent Management',
      expanded: false,
      order: 60
    },
    {
      id: 'Payment Details',
      label: 'Payment Details',
      expanded: false,
      order: 70,
      feature_flag: 'ff_contact_payment'
    }
  ],
  attributes: [
    {
      group: 'Personal Details',
      name: 'test_number_attribute',
      type: 'string',
      label: 'Test number attribute',
      order: 0,
      id: '6e7d1380-643e-4647-ae52-d52572ed46aa',
      _purpose: []
    },
    {
      group: 'Personal Details',
      name: 'full_name',
      type: 'string',
      label: 'Full Name',
      order: 1,
      id: '00f410c8-389c-4720-b4a0-0b3dda90640d',
      _purpose: []
    },
    {
      label: 'Salutation',
      name: 'salutation',
      type: 'select',
      options: [
        null,
        'Mr.',
        'Ms. / Mrs.',
        'Company',
        'Contact Person',
        'Company/Contact Person',
        'Spouse',
        'Family',
        'Ownership',
        'Assembly',
        'Other'
      ],
      group: 'Personal Details',
      default_value: '',
      layout: 'half_width',
      protected: true,
      order: 2,
      id: '79109977-651d-450b-b829-96b480cec8ec',
      _purpose: []
    },
    {
      label: 'Title',
      name: 'title',
      type: 'select',
      options: [null, 'Dr.', 'Prof.', 'Prof. Dr.'],
      group: 'Personal Details',
      layout: 'half_width',
      protected: true,
      order: 3,
      id: '81d2e0db-3c54-488f-a1f6-5aa11fa7bf99',
      _purpose: []
    },
    {
      label: 'Last Name',
      name: 'last_name',
      type: 'string',
      group: 'Personal Details',
      layout: 'full_width',
      protected: true,
      order: 4,
      id: 'ba4fc31c-80e3-420a-a6f4-fb7c31735f6a',
      _purpose: []
    },
    {
      label: 'Customer Number',
      name: 'customer_number',
      type: 'string',
      show_in_table: true,
      group: 'Personal Details',
      layout: 'half_width',
      protected: true,
      order: 5,
      id: '3a45bbb3-62b3-4bd6-8966-a3905df8eb2b',
      _purpose: []
    },
    {
      label: 'First Name',
      name: 'first_name',
      type: 'string',
      group: 'Personal Details',
      layout: 'half_width',
      protected: true,
      order: 6,
      id: 'ebe52619-5e9a-4f02-8f0c-71ff91486704',
      _purpose: []
    },
    {
      label: 'Date of Birth',
      name: 'birthdate',
      type: 'date',
      group: 'Personal Details',
      layout: 'half_width',
      protected: true,
      order: 7,
      id: 'de0a72f8-58a7-4b8b-b7e1-65c8d258525a',
      _purpose: []
    },
    {
      label: 'Associated Products',
      name: 'product_types',
      type: 'tags',
      group: 'Personal Details',
      layout: 'full_width',
      suggestions: [
        'Solar',
        'Charge',
        'Generic',
        'Storage',
        'Power',
        'Gas',
        'CHP',
        'House connection - electricity',
        'House connection - gas',
        'House connection - water',
        'House connection - heat',
        'House connection - broadband',
        'Heating',
        'Heat pump',
        'Carsharing',
        'Broadband',
        'Commissioning power',
        'Commissioning water',
        'Commissioning gas',
        'Registration of Micro Power Plants',
        'smarthome'
      ],
      protected: true,
      order: 8,
      id: 'faf19d95-225d-4c19-a977-6d1f0188fbdc',
      _purpose: []
    },
    {
      group: 'Personal Details',
      name: 'calculated_unit',
      type: 'number',
      label: 'Calculated Unit',
      readonly: false,
      required: false,
      hidden: false,
      multiline: false,
      rows: 2,
      repeatable: false,
      has_primary: false,
      order: 9,
      id: 'd8441dfd-0c21-4ef1-ba7a-c93a4c776f28',
      _purpose: []
    },
    {
      group: 'Personal Details',
      name: 'number_attribute',
      type: 'number',
      label: 'number attribute',
      readonly: false,
      required: false,
      hidden: false,
      multiline: false,
      rows: 2,
      repeatable: false,
      has_primary: false,
      order: 10,
      id: '865fa595-6733-4ac7-8c9f-604f1c6e8575',
      _purpose: []
    },
    {
      group: 'Personal Details',
      name: 'company_name',
      type: 'string',
      label: 'company_name',
      order: 11,
      id: '9066c776-9fe7-49e0-9b0c-bd589b7284cb',
      _purpose: []
    },
    {
      group: 'Personal Details',
      name: 'repeatable',
      type: 'string',
      label: 'repeatable',
      repeatable: true,
      order: 12,
      id: '40478df9-31dc-48f9-ad8b-41957da98ee1',
      _purpose: []
    },
    {
      group: 'Personal Details',
      name: 'dob',
      type: 'datetime',
      label: 'DOB',
      readonly: false,
      required: false,
      hidden: false,
      multiline: false,
      rows: 2,
      repeatable: false,
      has_primary: false,
      id: '448e95fb-7ee9-4ba1-b828-b541b221d461',
      _purpose: []
    },
    {
      label: 'Contact Owner',
      name: 'contact_owner',
      type: 'relation_user',
      multiple: false,
      show_in_table: true,
      group: 'Contact Details',
      layout: 'full_width',
      protected: true,
      id: '24bdc5d9-cf18-4d0b-b33f-a6f54a0c0662'
    },
    {
      label: 'Email',
      name: 'email',
      type: 'email',
      show_in_table: true,
      group: 'Contact Details',
      layout: 'full_width',
      repeatable: true,
      has_primary: true,
      protected: true,
      id: '6192f3f4-03c1-4952-8597-cdd175da01f2'
    },
    {
      label: 'Phone',
      name: 'phone',
      type: 'phone',
      show_in_table: true,
      group: 'Contact Details',
      layout: 'full_width',
      repeatable: true,
      has_primary: true,
      protected: true,
      id: 'b69f348a-b4b0-4e09-a092-742ef2c4ded4'
    },
    {
      name: 'marketing_permission',
      type: 'boolean',
      label: 'Marketing Permission',
      group: 'Contact Details',
      layout: 'half_width',
      deprecated: true,
      protected: true,
      id: '9a38a9e1-da85-4d9a-9411-34a4831369e8'
    },
    {
      group: 'Contact Details',
      name: 'test_phone_number',
      type: 'string',
      label: 'Test Phone Number',
      readonly: false,
      required: false,
      hidden: false,
      repeatable: true,
      has_primary: false,
      id: '33b8368a-3c94-46e7-bb2a-64198e76d0c7'
    },
    {
      label: 'Purposes',
      name: '_purpose',
      show_in_table: true,
      hidden: true,
      type: 'purpose',
      id: '18b5de40-7881-4b9f-89d6-fc573919e7f7'
    },
    {
      name: 'account',
      type: 'relation',
      relation_type: 'has_many',
      label: 'Account',
      hide_label: true,
      allowedSchemas: ['account'],
      show_in_table: true,
      group: 'Company Details',
      layout: 'full_width',
      has_primary: true,
      enable_relation_picker: true,
      enable_relation_tags: true,
      protected: true,
      id: 'c8992ec7-80e4-496a-9ec8-dd323cb1498a'
    },
    {
      label: 'Address',
      name: 'address',
      type: 'address',
      group: 'Address Details',
      layout: 'full_width',
      repeatable: true,
      has_primary: true,
      protected: true,
      id: 'a6dd42cb-2dcf-4f10-a6a6-86a6d849d41f'
    },
    {
      label: 'Email Marketing',
      type: 'consent',
      show_in_table: true,
      name: 'consent_email_marketing',
      group: 'Marketing Consent Management',
      topic: 'EMAIL_MARKETING',
      identifiers: ['email[*].email'],
      protected: true,
      id: '2045127e-b999-411b-a782-b9a82c149e32'
    },
    {
      label: 'SMS Marketing',
      type: 'consent',
      name: 'consent_sms_marketing',
      group: 'Marketing Consent Management',
      topic: 'SMS_MARKETING',
      identifiers: ['phone[*].phone'],
      protected: true,
      id: '6f8adf08-96fc-4d42-a6a7-aed5a82e6776'
    },
    {
      label: 'Direct Phone Marketing',
      type: 'consent',
      name: 'consent_phone_call',
      group: 'Marketing Consent Management',
      topic: 'PHONE_CALL_MARKETING',
      identifiers: ['phone[*].phone'],
      protected: true,
      id: '8112c3db-3d09-42a9-9685-5df45f87bd69'
    },
    {
      label: 'Print Marketing',
      type: 'consent',
      name: 'consent_print_marketing',
      group: 'Marketing Consent Management',
      topic: 'PRINT_MARKETING',
      protected: true,
      id: '2916daca-2966-4056-8201-94fce2548f38'
    },
    {
      label: 'Payment',
      name: 'payment',
      type: 'payment',
      group: 'Payment Details',
      layout: 'full_width',
      repeatable: true,
      has_primary: true,
      protected: true,
      feature_flag: 'ff_contact_payment',
      id: 'd84dee14-115f-49e6-9b3b-cc78b48b5748'
    },
    {
      group: 'Personal Details',
      name: 'mj_test',
      type: 'string',
      id: '29d9960a-7efb-4ccc-8177-718075741c80',
      label: 'MJ Test',
      _purpose: []
    },
    {
      group: 'Personal Details',
      name: 'new_attribute_1679427761812',
      type: 'string',
      id: '2416637c-ece2-4e17-98f9-b47b31b5e563',
      label: 'New Attribute'
    }
  ],
  capabilities: [
    {
      name: 'attributes',
      title: 'Attributes',
      ui_hooks: [
        {
          hook: 'EntityDetailsV2:Tab',
          title: 'Overview',
          component: 'EntityOverview',
          order: 0
        }
      ]
    },
    {
      name: 'relations',
      title: 'Relations',
      ui_hooks: [
        {
          hook: 'EntityDetailsV2:Tab',
          title: 'Relations',
          component: 'EntityRelations',
          order: 10
        }
      ]
    },
    {
      name: 'files',
      title: 'Files',
      feature_flag: 'ff_files_enabled',
      ui_hooks: [
        {
          hook: 'EntityDetailsV2:Tab',
          title: 'Files',
          component: 'EntityFiles',
          order: 50
        }
      ]
    },
    {
      name: 'messages',
      title: 'Messages',
      feature_flag: 'ff_messaging_enabled',
      ui_hooks: [
        {
          hook: 'EntityDetailsV2:Tab',
          title: 'Messages',
          import: '@epilot360/messaging',
          order: 51,
          requiredPermission: {
            action: 'message:view'
          }
        }
      ]
    },
    {
      name: 'activity_feed',
      title: 'Activity',
      ui_hooks: [
        {
          hook: 'ActivityPilot:View',
          route: 'activity_feed',
          order: 0
        }
      ]
    },
    {
      name: 'ivy_opportunities',
      title: 'Opportunities (classic)',
      legacy: true,
      ui_hooks: [
        {
          hook: 'EntityDetailsV2:Tab',
          title: 'Opportunities (classic)',
          import: '@epilot360/opportunities',
          order: 5
        }
      ]
    },
    {
      name: 'automation',
      title: 'Automation',
      feature_flag: 'ff_automation_enabled',
      ui_hooks: [
        {
          hook: 'ActivityPilot:View',
          route: 'automation',
          order: 30,
          import: '@epilot360/automation-capability'
        },
        {
          hook: 'ActivityPilot:QuickAction',
          icon: 'play_circle_filled',
          route: '?activity_pilot=automation',
          order: 30
        }
      ]
    },
    {
      name: 'workflow',
      title: 'Workflow',
      feature_flag: 'ff_workflows_enabled',
      ui_hooks: [
        {
          hook: 'ActivityPilot:View',
          route: 'workflow',
          import: '@epilot360/workflows-sidebar',
          order: 20,
          header: true,
          requiredPermission: {
            action: 'workflow:execution:view'
          }
        },
        {
          hook: 'ActivityPilot:QuickAction',
          icon: 'custom_workflow_icon',
          route: '?activity_pilot=workflow',
          order: 20,
          requiredPermission: {
            action: 'workflow:execution:trigger'
          }
        },
        {
          hook: 'EntityDetailsV2:Header',
          title: 'Workflow Overview',
          component: 'WorkflowOverview'
        }
      ]
    },
    {
      name: 'customer_messaging',
      title: 'Messages',
      feature_flag: 'ff_messaging_enabled',
      ui_hooks: [
        {
          hook: 'ActivityPilot:QuickAction',
          icon: 'email',
          route: '?drawer=messaging',
          order: 15,
          requiredPermission: {
            action: 'message:send'
          }
        }
      ]
    },
    {
      name: 'notes',
      title: 'Notes',
      attributes: [
        {
          name: 'notes',
          label: 'Notes',
          type: 'internal'
        }
      ],
      ui_hooks: [
        {
          hook: 'ActivityPilot:QuickAction',
          icon: 'task',
          route: '?drawer=notes',
          order: 50,
          requiredPermission: {
            action: 'entity:create',
            resource: 'comment*'
          }
        },
        {
          hook: 'EntityDetailsV2:Tab',
          title: 'Notes',
          import: '@epilot360/notes',
          order: 52,
          requiredPermission: {
            action: 'entity:view',
            resource: 'comment*'
          }
        }
      ]
    },
    {
      name: 'metadata',
      title: 'Metadata',
      ui_hooks: [
        {
          hook: 'EntityDetailsV2:Tab',
          order: 500,
          title: 'Metadata',
          component: 'EntityUnmappedFields'
        }
      ]
    },
    {
      name: 'workflow-in-entities',
      title: 'Workflow in Entity Table',
      feature_flag: 'ff_wf_in_entities',
      ui_hooks: [
        {
          hook: 'EntityTable:Column',
          field: 'workflows.primary.task_duedate',
          label: 'Workflow Next Open Task',
          component: 'WorkflowCell',
          width: 450
        }
      ]
    },
    {
      name: 'partnering',
      title: 'Partnering',
      feature_flag: 'ff_partner_sharing_v2',
      ui_hooks: [
        {
          hook: 'EntityTable:Column',
          sortable: false,
          field: 'partner',
          label: 'Partner',
          component: 'PartnerCell',
          width: 300
        }
      ]
    }
  ],
  comment: '',
  draft: false,
  created_at: '2022-05-09T11:56:45.534Z',
  updated_at: '2023-03-21T19:42:52.645Z',
  version: 123,
  created_by: '10011645',
  source: {
    id: '10011645',
    type: 'user'
  }
}
