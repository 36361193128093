export const MOCK_OPPORTUNITY_SCHEMA = {
  id: 'c2d70f63-2eb2-41b6-9bdf-a3e52bb54ec9',
  blueprint: '0fd09294-068d-4e4e-95f8-4d82b70b5c2c',
  name: 'Opportunity',
  plural: 'Opportunities',
  slug: 'opportunity',
  icon: 'repeat',
  published: true,
  title_template:
    '{{#if opportunity_title}}{{opportunity_title}}{{else}}{{opportunity_number}}{{/if}}',
  feature_flag: 'ff_opportunities_enabled',
  enable_setting: ['ecommerce_opportunities_preview'],
  ui_config: {
    table_view: {
      view_type: 'default',
      row_actions: ['view', 'edit', 'duplicate', 'share', 'delete'],
      dropdown_items: [
        {
          type: 'entity',
          entity: 'submission'
        },
        {
          type: 'entity',
          entity: 'order'
        },
        {
          type: 'entity',
          entity: 'opportunity'
        },
        {
          type: 'link',
          title: 'Opportunities (Legacy)',
          uri: '/app/ivy/$/ivy/pro/Portal/epilot_cloud/15FDE488211714BF/opportunity.ivp',
          legacy: true
        },
        {
          type: 'entity',
          entity: 'contract',
          feature_flag: 'ff_contracts'
        }
      ],
      classic_view:
        '/app/ivy/$/ivy/pro/Portal/epilot_cloud/15FDE488211714BF/opportunity.ivp'
    },
    create_view: {
      view_type: 'default'
    },
    edit_view: {
      view_type: 'default'
    },
    list_item: {
      summary_attributes: ['_created_at', 'status', 'email']
    },
    sharing: {
      show_sharing_button: true
    }
  },
  group_settings: [
    {
      id: 'Opportunity Details',
      label: 'Opportunity Details',
      expanded: true,
      order: 10
    },
    {
      id: 'Customer Information',
      label: 'Customer Information',
      expanded: true,
      order: 20
    },
    {
      id: 'Address Information',
      label: 'Address Information',
      expanded: true,
      order: 30
    },
    {
      id: 'Appointments & Dates',
      label: 'Appointments & Dates',
      expanded: true,
      order: 50
    }
  ],
  attributes: [
    {
      group: 'Opportunity Details',
      name: 'my_status_field',
      type: 'status',
      label: 'Current Step',
      order: 1,
      readonly: false,
      required: false,
      hidden: false,
      repeatable: false,
      has_primary: false,
      options: [
        {
          value: 'draft',
          title: 'Draft'
        },
        {
          value: 'quote',
          title: 'Open for Quote'
        },
        {
          value: 'placed',
          title: 'Placed'
        },
        {
          value: 'complete',
          title: 'Complete'
        },
        {
          value: 'cancelled',
          title: 'Cancelled'
        }
      ]
    },
    {
      group: 'Opportunity Details',
      name: 'my_second_status_field',
      type: 'status',
      label: 'Current Section',
      order: 2,
      readonly: false,
      required: false,
      hidden: false,
      multiline: false,
      rows: 2,
      repeatable: false,
      has_primary: false,
      options: ['romania', 'germany']
    },
    {
      label: 'Opportunity Title',
      name: 'opportunity_title',
      type: 'string',
      show_in_table: true,
      group: 'Opportunity Details',
      layout: 'half_width',
      protected: true,
      order: 3
    },
    {
      label: 'Opportunity Number',
      name: 'opportunity_number',
      type: 'sequence',
      show_in_table: true,
      group: 'Opportunity Details',
      layout: 'half_width',
      readonly: true,
      protected: true,
      feature_flag: 'ff_opportunity_assignee',
      order: 4,
      prefix: 'OP-',
      start_number: 100
    },
    {
      group: 'Opportunity Details',
      name: 'date_and_time',
      type: 'datetime',
      label: 'Date and Time',
      layout: 'half_width',
      order: 5
    },
    {
      label: 'Source',
      name: 'source',
      type: 'link',
      readonly: true,
      group: 'Opportunity Details',
      protected: true,
      default_value: {
        title: 'manual',
        href: null,
        source_type: 'manual'
      },
      order: 6
    },
    {
      label: 'Assignee',
      name: 'assignee',
      type: 'relation_user',
      show_in_table: true,
      group: 'Opportunity Details',
      layout: 'full_width',
      protected: true,
      feature_flag: 'ff_opportunity_due_date',
      order: 7
    },
    {
      label: 'Description',
      name: 'description',
      type: 'string',
      layout: 'full_width',
      group: 'Opportunity Details',
      multiline: true,
      protected: true,
      order: 8
    },
    {
      label: 'Due Date',
      name: 'due_date',
      type: 'datetime',
      show_in_table: true,
      group: 'Opportunity Details',
      layout: 'full_width',
      protected: true,
      feature_flag: 'ff_opportunity_status',
      order: 9
    },
    {
      name: 'customer',
      type: 'relation',
      relation_type: 'has_many',
      label: 'Customer',
      allowedSchemas: ['contact', 'account'],
      show_in_table: true,
      group: 'Customer Information',
      layout: 'full_width',
      has_primary: true,
      enable_relation_picker: true,
      enable_relation_tags: true,
      protected: true
    },
    {
      name: 'billing_address',
      label: 'Billing Address',
      group: 'Address Information',
      type: 'relation_address',
      layout: 'full_width',
      show_in_table: false,
      has_primary: false,
      constraints: {
        limit: 1
      },
      protected: true
    },
    {
      name: 'delivery_address',
      label: 'Delivery Address',
      group: 'Address Information',
      type: 'relation_address',
      layout: 'full_width',
      show_in_table: false,
      has_primary: false,
      constraints: {
        limit: 1
      },
      protected: true
    },
    {
      name: 'address',
      label: 'Additional Addresses',
      group: 'Address Information',
      type: 'relation_address',
      layout: 'full_width',
      show_in_table: false,
      has_primary: false,
      protected: true
    },
    {
      label: 'Orders',
      name: 'items',
      type: 'relation',
      show_in_table: true,
      hidden: true,
      allowedSchemas: ['order'],
      enable_relation_picker: false,
      enable_relation_tags: false,
      protected: true
    },
    {
      label: 'Products',
      name: 'products',
      type: 'relation',
      show_in_table: true,
      hidden: true,
      allowedSchemas: ['product'],
      enable_relation_picker: false,
      enable_relation_tags: false,
      protected: true
    },
    {
      label: 'Dates',
      name: 'dates',
      type: 'datetime',
      show_in_table: true,
      hide_label: true,
      group: 'Appointments & Dates',
      layout: 'full_width',
      repeatable: true,
      protected: true
    },
    {
      group: 'Appointments & Dates',
      name: 'my_custom_date',
      type: 'date',
      label: 'My custom date',
      readonly: false,
      required: false,
      hidden: false,
      multiline: false,
      rows: 2,
      repeatable: false,
      has_primary: false
    },
    {
      group: 'Appointments & Dates',
      name: 'my_date_time',
      type: 'datetime',
      label: 'my_date_time',
      readonly: false,
      required: false,
      hidden: false,
      multiline: false,
      rows: 2,
      repeatable: false,
      has_primary: false
    },
    {
      group: 'Appointments & Dates',
      name: 'my_custom_date_2',
      type: 'date',
      label: 'My custom date 2',
      readonly: false,
      required: false,
      hidden: false,
      multiline: false,
      rows: 2,
      repeatable: true,
      has_primary: true
    }
  ],
  capabilities: [
    {
      name: 'attributes',
      title: 'Overview',
      ui_hooks: [
        {
          hook: 'EntityDetailsV2:Tab',
          title: 'Overview',
          component: 'EntityOverview',
          order: 0
        }
      ]
    },
    {
      name: 'consents',
      title: 'Consents',
      feature_flag: 'ff_local_consents_enabled',
      ui_hooks: [
        {
          hook: 'EntityAttributes:Group',
          title: 'Consents',
          component: 'LocalConsents',
          order: 40
        }
      ]
    },
    {
      name: 'relations',
      title: 'Relations',
      ui_hooks: [
        {
          hook: 'EntityDetailsV2:Tab',
          title: 'Relations',
          component: 'EntityRelations',
          order: 10
        }
      ]
    },
    {
      name: 'files',
      title: 'Files',
      feature_flag: 'ff_files_enabled',
      ui_hooks: [
        {
          hook: 'EntityDetailsV2:Tab',
          title: 'Files',
          component: 'EntityFiles',
          order: 50
        }
      ]
    },
    {
      name: 'messages',
      title: 'Messages',
      feature_flag: 'ff_messaging_enabled',
      ui_hooks: [
        {
          hook: 'EntityDetailsV2:Tab',
          title: 'Messages',
          import: '@epilot360/messaging',
          order: 51,
          requiredPermission: {
            action: 'message:view'
          }
        }
      ]
    },
    {
      name: 'activity_feed',
      title: 'Activity',
      ui_hooks: [
        {
          hook: 'ActivityPilot:View',
          route: 'activity_feed',
          order: 0
        }
      ]
    },
    {
      name: 'automation',
      title: 'Automation',
      feature_flag: 'ff_automation_enabled',
      ui_hooks: [
        {
          hook: 'ActivityPilot:View',
          route: 'automation',
          order: 30,
          import: '@epilot360/automation-capability'
        },
        {
          hook: 'ActivityPilot:QuickAction',
          icon: 'play_circle_filled',
          route: '?activity_pilot=automation',
          order: 30
        }
      ]
    },
    {
      name: 'workflow',
      title: 'Workflow',
      feature_flag: 'ff_workflows_enabled',
      ui_hooks: [
        {
          hook: 'ActivityPilot:View',
          route: 'workflow',
          import: '@epilot360/workflows-sidebar',
          order: 20,
          header: false,
          requiredPermission: {
            action: 'workflow:execution:view'
          }
        },
        {
          hook: 'ActivityPilot:QuickAction',
          icon: 'custom_workflow_icon',
          route: '?activity_pilot=workflow',
          order: 20,
          requiredPermission: {
            action: 'workflow:execution:trigger'
          }
        }
      ]
    },
    {
      name: 'quoting_relations',
      title: 'Quotes & Orders',
      ui_hooks: [
        {
          hook: 'EntityAttributes:Group',
          order: 40,
          title: 'Quotes & Orders',
          component: 'QuotingItems'
        }
      ]
    },
    {
      name: 'unmapped_attributes',
      title: 'Metadata',
      ui_hooks: [
        {
          hook: 'EntityDetailsV2:Tab',
          order: 500,
          title: 'Metadata',
          component: 'EntityUnmappedFields'
        }
      ]
    },
    {
      name: 'notes',
      title: 'Notes',
      attributes: [
        {
          name: 'notes',
          label: 'Notes',
          type: 'internal'
        }
      ],
      ui_hooks: [
        {
          hook: 'ActivityPilot:QuickAction',
          icon: 'task',
          route: '?drawer=notes',
          order: 50,
          requiredPermission: {
            action: 'note:create'
          }
        },
        {
          hook: 'EntityDetailsV2:Tab',
          title: 'Notes',
          import: '@epilot360/notes',
          order: 52,
          requiredPermission: {
            action: 'note:view'
          }
        }
      ]
    },
    {
      name: 'journey_data',
      title: 'Journey Data',
      feature_flag: 'ff_journey_data_capability',
      ui_hooks: [
        {
          hook: 'EntityAttributes:Group',
          title: 'Journey Data',
          import: '@epilot360/journey-data',
          order: 60
        }
      ]
    },
    {
      name: 'customer_messaging',
      title: 'Messages',
      feature_flag: 'ff_opportunity_messaging_enabled',
      ui_hooks: [
        {
          hook: 'ActivityPilot:QuickAction',
          icon: 'email',
          route: '?drawer=messaging',
          order: 15,
          requiredPermission: {
            action: 'message:send'
          }
        }
      ]
    }
  ],
  draft: false,
  created_at: '2022-05-09T11:56:45.665Z',
  updated_at: '2022-08-31T21:02:48.830Z',
  version: 102,
  source: {
    id: 'e5fee37e-7818-481b-bab1-ae563592d696',
    type: 'blueprint'
  },
  comment: 'Generated patch for entity opportunity, order and price',
  created_by: '10010729',
  explicit_search_mappings: {
    journey_data: {
      type: 'flattened'
    }
  }
}
