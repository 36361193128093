import isNil from 'lodash/fp/isNil'

export const parsedTranslations = (overriddenTranslations: any) => {
  try {
    if (!isNil(overriddenTranslations)) {
      return JSON.parse(overriddenTranslations)
    }

    return null
  } catch (error) {
    try {
      return JSON.parse(overriddenTranslations)
    } catch (e) {
      console.error('Error parsing translation JSON', error)
    }
  }
}
