import { Currency } from '@epilot/pricing'
import DineroConstructor from 'dinero.js'
import { TFunction } from 'i18next'

import { BillingEvent, BillingPeriod } from '../api/ApiTypes'

import CommonUtils, { getTimeSliceValidity } from './CommonUtils'
import { PaymentType } from './OrderUtils'

export enum RepeatablePaymentOptionType {
  INVOICE = 'payment_invoice',
  SEPA = 'payment_sepa',
  CASH = 'payment_cash'
}

export interface RepeatablePaymentOptionInvoice {
  id?: string
  _id?: string
  type: RepeatablePaymentOptionType.INVOICE
  _tags?: string[]
  data?: {
    start_date?: string
    end_date?: string
  }
}

export interface RepeatablePaymentOptionInvoiceCash {
  id?: string
  _id?: string
  type: RepeatablePaymentOptionType.CASH
  _tags: string[]
  data?: {
    amount: string
    start_date?: string
    end_date?: string
  }
}

export interface RepeatablePaymentOptionSEPA {
  id?: string
  _id?: string
  type: RepeatablePaymentOptionType.SEPA
  _tags: string[]
  data: {
    bank_name: string
    iban: string
    bic_number: string
    fullname: string
    start_date?: string
    end_date?: string
  }
}

export type RepeatablePaymentOption =
  | RepeatablePaymentOptionInvoice
  | RepeatablePaymentOptionSEPA
  | RepeatablePaymentOptionInvoiceCash

export interface RelationalPayment {
  entity_id: string
  path: string
  _id: string
}

export const getPaymentTypeTranslation = (
  t: TFunction,
  type: PaymentType,
  billingPeriod: BillingPeriod
): string => {
  if (type === 'one_time') {
    return t('my_orders.one_time')
  }

  const types = {
    weekly: t('my_orders.weekly'),
    monthly: t('my_orders.monthly'),
    every_quarter: t('my_orders.every_quarter'),
    every_6_months: t('my_orders.every_6_months'),
    yearly: t('my_orders.yearly')
  }

  return types[billingPeriod] ?? ''
}

export const formatIban = (input) => {
  const value = input.replace(/\s/g, '') // Remove spaces

  if (value.length <= 6) return value // No masking if length is too short

  // Mask all characters except the first 2 and last 4
  const firstTwo = value.slice(0, 4)
  const lastFour = value.slice(-4)
  const maskedSection = value.slice(4, -4).replace(/./g, 'X') // Mask middle characters
  // Combine the sections
  const combined = firstTwo + maskedSection + lastFour

  // Format with spaces every 4 characters, but avoid trailing spaces
  return combined
    .match(/.{1,4}/g) // Match groups of up to 4 characters
    .join(' ') // Join the groups with a space
}

export const getBalance = (amount: number, currency: Currency) => {
  const dineroAmount = DineroConstructor({
    amount,
    currency,
    precision: 2
  })

  return (
    <>
      {`${dineroAmount.isNegative() ? '-' : ''} ${CommonUtils.toLocalePrice(
        amount,
        currency
      )}`}
    </>
  )
}

export const getCurrencySymbol = (currency: Currency) => {
  switch (currency) {
    case 'EUR':
      return '€'
    case 'USD':
      return '$'
    default:
      return ''
  }
}

export const getBillingEventStatus = (billingEvent: BillingEvent) => {
  const paid = !!billingEvent.paid_date
  const overdue = new Date() > new Date(billingEvent.due_date)

  if (paid) {
    return 'paid'
  }

  if (!paid && overdue) {
    return 'overdue'
  }

  if (!paid && !overdue) {
    return 'open'
  }

  return ''
}

export const formatBillingAmount = (amount: string) => {
  if (amount.includes(',')) {
    return amount
  }

  return amount.replace('.', ',')
}

export function formatDecimalToComma(number: number, locale = 'de-DE'): string {
  return new Intl.NumberFormat(locale, { minimumFractionDigits: 2 }).format(
    number
  )
}

export function getPaymentOptionValidity(
  paymentOption: RepeatablePaymentOption,
  date: Date = null
) {
  const startDate = paymentOption?.data?.start_date
  const endDate = paymentOption?.data?.end_date

  const dateString = (date || new Date()).toISOString().slice(0, 10)

  return getTimeSliceValidity(startDate, endDate, dateString)
}
