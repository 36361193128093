import { getClient, Components } from '@epilot/customer-portal-client'

import AuthUtils from 'utils/AuthUtils'

import { ENV_CONFIG } from '../EnvConfig'

const CustomerApiClient = getClient()

CustomerApiClient.defaults.baseURL = ENV_CONFIG.CUSTOMER_PORTAL_API_URL

if (CustomerApiClient.interceptors.request['handlers'].length > 3) {
  console.error(
    'Sanity check: This client already has more than 3 request interceptors, refusing to add more!',
    {
      baseURL: CustomerApiClient.defaults.baseURL,
      interceptors: CustomerApiClient.interceptors
    }
  )
} else {
  CustomerApiClient.interceptors.request.use(async (request) => {
    const token: string = await AuthUtils.getAccessToken()

    const portalId: string = AuthUtils.getOrgId()

    const isNotPublicRequest = !request.url.includes('/public')

    if (portalId && isNotPublicRequest) {
      request.headers['x-portal-id'] = portalId
    }

    if (token && isNotPublicRequest) {
      request.headers['authorization'] = `Bearer ${token}`
    }

    return request
  })
}

export const PORTAL_TYPES = {
  endCustomerPortal: 'END_CUSTOMER_PORTAL',
  installerPortal: 'INSTALLER_PORTAL'
}

export type { Components }
export default CustomerApiClient
