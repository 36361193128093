import { useMemo } from 'react'

import { QUERY_PARAM, useQueryParams } from './useQueryParams'

export const useQueryParam = (paramName: QUERY_PARAM): string => {
  const queryParams = useQueryParams()

  const value = useMemo(
    () => queryParams.get(paramName),
    [queryParams, paramName]
  )

  return value
}
