import { createContext, ReactNode, useContext, useMemo } from 'react'
import { useQuery } from 'react-query'

import { EntitySchemaItem } from 'api/ApiTypes'
import CustomerApiClient from 'api/CustomerApiClient'
import { CUSTOMER_QUERY_IDS } from 'api/Queries'
import { isConfigMode, isPreviewMode } from 'utils/StyleUtils'

import { MOCK_CONTACT_SCHEMA } from '../mock-db/schemas/MockContactSchema'
import { MOCK_OPPORTUNITY_SCHEMA } from '../mock-db/schemas/MockOpportunitySchema'
import { MOCK_ORDER_SCHEMA } from '../mock-db/schemas/MockOrderSchema'
import { EntityType } from '../utils/EntityUtils'

import AuthContext from './AuthContext'

const MOCK_SCHEMAS = [
  MOCK_OPPORTUNITY_SCHEMA,
  MOCK_ORDER_SCHEMA,
  MOCK_CONTACT_SCHEMA
]

interface SchemasContextState {
  isLoading: boolean
  schemas: EntitySchemaItem[]
}

const defaultState: SchemasContextState = {
  isLoading: true,
  schemas: []
}

const SchemasContext = createContext<SchemasContextState>(defaultState)

interface SchemasContextProps {
  children: ReactNode
}

const SchemasContextProvider = (props: SchemasContextProps) => {
  const { data: authData } = useContext(AuthContext)
  const isInPreviewMode = isPreviewMode()

  const schemasQuery = useQuery(
    CUSTOMER_QUERY_IDS.GET_SCHEMAS,
    () => CustomerApiClient.getSchemas() as any,
    {
      enabled: Boolean(authData.user) && !isConfigMode() && !isPreviewMode()
    }
  )

  const schemas = useMemo(() => {
    if (isInPreviewMode) {
      return MOCK_SCHEMAS
    }

    return schemasQuery?.data?.schemas || []
  }, [schemasQuery?.data?.schemas, isInPreviewMode])

  return (
    <SchemasContext.Provider
      value={{ schemas, isLoading: schemasQuery.isLoading }}
    >
      {props.children}
    </SchemasContext.Provider>
  )
}

const useContextSchema = (schemaSlug: EntityType) => {
  const { schemas, isLoading } = useContext(SchemasContext)

  const schema = schemas.find(
    (schema: EntitySchemaItem) => schema.slug === schemaSlug
  )

  return { schema, isLoading }
}

export { SchemasContextProvider, useContextSchema }
export default SchemasContext
