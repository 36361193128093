import { useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import {
  PERSISTED_TRANSLATION_OVERRIDES,
  TranslationContext
} from 'context/TranslationContext'

import PortalConfigContext from '../../context/PortalConfigContext'
import { parsedTranslations } from '../../utils/ConfigModeUtils'
import { getCurrentLanguage } from '../../utils/LanguageUtils'
import { isConfigMode } from '../../utils/StyleUtils'

const replaceOptions = (overrideTranslation: string, options: any): string => {
  let translatedText: string = overrideTranslation

  for (const option in options) {
    const regex = new RegExp(`{{${option}}}`, 'gim')

    translatedText = translatedText.replace(regex, options[option])
  }

  return translatedText
}

const useEcpTranslation = () => {
  const { t, i18n } = useTranslation()
  const translationContext = useContext(TranslationContext)

  const { data: portalConfigData } = useContext(PortalConfigContext)

  const translate = useCallback(
    (translationKey: string, options?: any) => {
      if (isConfigMode()) {
        const persistedTranslationOverrides =
          parsedTranslations(
            localStorage.getItem(PERSISTED_TRANSLATION_OVERRIDES)
          ) || translationContext.data?.overrides

        const overrideTranslation: string =
          persistedTranslationOverrides?.[getCurrentLanguage()]?.[
            translationKey
          ]

        if (overrideTranslation) {
          return replaceOptions(overrideTranslation, options)
        }
      }

      const overrideTranslation: string =
        translationContext.data?.overrides?.[getCurrentLanguage()]?.[
          translationKey
        ]

      if (overrideTranslation) {
        return replaceOptions(overrideTranslation, options)
      }

      const primaryOverrideTranslation: string =
        portalConfigData?.originalConfig?.config?.translationOverrides?.[
          getCurrentLanguage()
        ]?.[translationKey]

      if (primaryOverrideTranslation) {
        return replaceOptions(primaryOverrideTranslation, options)
      }

      return t(translationKey, options)
    },
    [
      translationContext.data?.overrides,
      portalConfigData?.originalConfig?.config?.translationOverrides,
      t
    ]
  )

  return { t: translate, i18n }
}

export default useEcpTranslation
