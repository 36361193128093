export const MOCK_ORDER_SCHEMA = {
  id: '7b1c881c-4697-462e-a09f-f7e833401965',
  blueprint: '0fd09294-068d-4e4e-95f8-4d82b70b5c2c',
  name: 'Order',
  plural: 'Orders',
  slug: 'order',
  icon: 'shopping_cart',
  published: true,
  title_template: '{{order_number}}',
  feature_flag: 'ff_orders_enabled',
  enable_setting: ['ecommerce_preview'],
  ui_config: {
    table_view: {
      view_type: 'default',
      dropdown_items: [
        {
          type: 'entity',
          entity: 'submission'
        },
        {
          type: 'entity',
          entity: 'order'
        },
        {
          type: 'entity',
          entity: 'opportunity'
        },
        {
          type: 'link',
          title: 'Opportunities (Legacy)',
          uri: '/app/ivy/$/ivy/pro/Portal/epilot_cloud/15FDE488211714BF/opportunity.ivp',
          legacy: true
        },
        {
          type: 'entity',
          entity: 'contract',
          feature_flag: 'ff_contracts'
        }
      ],
      row_actions: ['view', 'edit', 'duplicate', 'share', 'delete']
    },
    create_view: {
      view_type: 'default'
    },
    edit_view: {
      view_type: 'default'
    },
    list_item: {
      summary_attributes: ['_created_at', 'status', 'total_amount']
    },
    sharing: {
      show_sharing_button: true
    }
  },
  group_settings: [
    {
      id: 'Order Details',
      label: 'Order Details',
      expanded: true,
      order: 0
    },
    {
      id: 'Customer Information',
      label: 'Customer Information',
      expanded: false,
      order: 5
    },
    {
      id: 'Address Information',
      label: 'Address Information',
      expanded: false,
      order: 6
    },
    {
      id: 'Appointments & Dates',
      label: 'Appointments & Dates',
      expanded: false,
      order: 7
    },
    {
      id: 'Advanced Options',
      label: 'Advanced Options',
      expanded: false,
      order: 8
    }
  ],
  attributes: [
    {
      label: 'Order Number',
      name: 'order_number',
      type: 'sequence',
      show_in_table: true,
      group: 'Order Details',
      layout: 'half_width',
      readonly: true,
      protected: false,
      order: 0,
      prefix: 'OR-',
      start_number: 100
    },
    {
      label: 'Status',
      name: 'status',
      type: 'status',
      options: [
        {
          value: 'draft',
          title: 'Draft'
        },
        {
          value: 'quote',
          title: 'Open for Quote'
        },
        {
          value: 'placed',
          title: 'Placed'
        },
        {
          value: 'complete',
          title: 'Complete'
        },
        {
          value: 'cancelled',
          title: 'Cancelled'
        },
        {
          value: 'open_for_acceptance',
          title: 'Open for Acceptance'
        }
      ],
      layout: 'half_width',
      group: 'Order Details',
      default_value: 'draft',
      protected: false,
      order: 1,
      readonly: false,
      required: false,
      hidden: false,
      repeatable: false,
      has_primary: false
    },
    {
      group: 'Order Details',
      name: 'status2',
      type: 'status',
      label: 'Status2',
      order: 2,
      readonly: false,
      required: false,
      hidden: false,
      multiline: false,
      rows: 2,
      repeatable: false,
      has_primary: false,
      options: [
        {
          value: 'draft',
          title: 'Draft'
        },
        {
          value: 'quote',
          title: 'Open for Quote'
        },
        {
          value: 'placed',
          title: 'Placed'
        },
        {
          value: 'complete',
          title: 'Complete'
        },
        {
          value: 'cancelled',
          title: 'Cancelled'
        }
      ]
    },
    {
      label: 'Expiration Date',
      name: 'expires_at',
      type: 'date',
      group: 'Order Details',
      show_in_table: false,
      layout: 'half_width',
      render_condition: 'status="quote"',
      constraints: {
        disablePast: true
      },
      protected: true,
      order: 3
    },
    {
      label: 'Source',
      name: 'source',
      type: 'link',
      readonly: true,
      group: 'Order Details',
      protected: true,
      default_value: {
        title: 'manual',
        href: null,
        source_type: 'manual'
      },
      order: 4
    },
    {
      group: 'Order Details',
      name: 'testing',
      type: 'string',
      label: 'Testing',
      order: 5
    },
    {
      group: 'Order Details',
      name: 'status_test',
      type: 'status',
      label: 'Status Test',
      options: ['cat', 'dog'],
      readonly: false,
      required: false,
      hidden: false,
      repeatable: false,
      has_primary: false
    },
    {
      name: 'customer',
      type: 'relation',
      relation_type: 'has_many',
      label: 'Customer',
      hide_label: true,
      allowedSchemas: ['contact', 'account'],
      show_in_table: true,
      group: 'Customer Information',
      layout: 'full_width',
      has_primary: true,
      enable_relation_picker: true,
      enable_relation_tags: true
    },
    {
      label: 'First Name',
      name: 'billing_first_name',
      type: 'string',
      group: 'Customer Information',
      layout: 'half_width',
      protected: true
    },
    {
      label: 'Last Name',
      name: 'billing_last_name',
      type: 'string',
      group: 'Customer Information',
      layout: 'half_width',
      protected: true
    },
    {
      label: 'Email',
      name: 'billing_email',
      type: 'string',
      show_in_table: true,
      group: 'Customer Information',
      layout: 'half_width',
      protected: true
    },
    {
      label: 'Phone',
      name: 'billing_phone',
      type: 'string',
      show_in_table: true,
      group: 'Customer Information',
      layout: 'half_width',
      protected: true
    },
    {
      label: 'Company name',
      name: 'billing_company_name',
      type: 'string',
      group: 'Customer Information',
      layout: 'half_width',
      protected: true
    },
    {
      label: 'Tax ID',
      name: 'billing_vat',
      type: 'string',
      group: 'Customer Information',
      layout: 'half_width',
      protected: true
    },
    {
      group: 'Customer Information',
      name: 'new_attribute_1660221915364',
      type: 'string',
      label: 'New Attribute'
    },
    {
      label: 'Billing Address',
      name: 'billing_address',
      type: 'address',
      group: 'Address Information',
      layout: 'full_width',
      repeatable: true,
      has_primary: false,
      constraints: {
        limit: 1
      },
      protected: true
    },
    {
      label: 'Delivery Address',
      name: 'delivery_address',
      type: 'address',
      group: 'Address Information',
      layout: 'full_width',
      repeatable: true,
      has_primary: false,
      constraints: {
        limit: 1
      }
    },
    {
      label: 'Additional Addresses',
      name: 'additional_addresses',
      type: 'address',
      group: 'Address Information',
      layout: 'full_width',
      repeatable: true,
      has_primary: false,
      protected: true
    },
    {
      label: 'Dates',
      name: 'dates',
      type: 'datetime',
      show_in_table: true,
      hide_label: true,
      group: 'Appointments & Dates',
      layout: 'full_width',
      repeatable: true,
      protected: true
    },
    {
      label: 'Products',
      name: 'products',
      type: 'relation',
      show_in_table: true,
      hidden: true,
      allowedSchemas: ['product'],
      enable_relation_picker: false,
      enable_relation_tags: false,
      protected: true
    },
    {
      label: 'Prices',
      name: 'prices',
      type: 'relation',
      show_in_table: true,
      hidden: true,
      allowedSchemas: ['price'],
      enable_relation_picker: false,
      enable_relation_tags: false,
      protected: true
    },
    {
      label: 'Memo',
      name: 'memo',
      type: 'string',
      group: 'Advanced Options',
      layout: 'full_width',
      multiline: true,
      protected: true
    },
    {
      label: 'Footer',
      name: 'footer_notes',
      type: 'string',
      group: 'Advanced Options',
      layout: 'full_width',
      multiline: true,
      protected: true
    }
  ],
  capabilities: [
    {
      name: 'attributes',
      title: 'Attributes',
      ui_hooks: [
        {
          hook: 'EntityDetailsV2:Tab',
          title: 'Overview',
          component: 'EntityOverview',
          order: 0
        }
      ]
    },
    {
      name: 'consents',
      title: 'Consents',
      feature_flag: 'ff_local_consents_enabled',
      ui_hooks: [
        {
          hook: 'EntityAttributes:Group',
          title: 'Consents',
          component: 'LocalConsents',
          order: 4
        }
      ]
    },
    {
      name: 'relations',
      title: 'Relations',
      ui_hooks: [
        {
          hook: 'EntityDetailsV2:Tab',
          title: 'Relations',
          component: 'EntityRelations',
          order: 10
        }
      ]
    },
    {
      name: 'files',
      title: 'Files',
      feature_flag: 'ff_files_enabled',
      ui_hooks: [
        {
          hook: 'EntityDetailsV2:Tab',
          title: 'Files',
          component: 'EntityFiles',
          order: 50
        }
      ]
    },
    {
      name: 'messages',
      title: 'Messages',
      feature_flag: 'ff_messaging_enabled',
      ui_hooks: [
        {
          hook: 'EntityDetailsV2:Tab',
          title: 'Messages',
          import: '@epilot360/messaging',
          order: 51,
          requiredPermission: {
            action: 'message:view'
          }
        }
      ]
    },
    {
      name: 'activity_feed',
      title: 'Activity',
      ui_hooks: [
        {
          hook: 'ActivityPilot:View',
          route: 'activity_feed',
          order: 0
        }
      ]
    },
    {
      name: 'payment_method',
      title: 'Payment Details',
      ui_hooks: [
        {
          hook: 'EntityAttributes:Group',
          order: 2,
          title: 'Payment details',
          component: 'PaymentMethodAttribute',
          group_expanded: false
        }
      ]
    },
    {
      name: 'order_items',
      title: 'Ordered Items',
      ui_hooks: [
        {
          hook: 'EntityAttributes:Group',
          order: 1,
          title: 'Ordered Items',
          component: 'PricingItems'
        }
      ]
    },
    {
      name: 'unmapped_attributes',
      title: 'Metadata',
      ui_hooks: [
        {
          hook: 'EntityDetailsV2:Tab',
          order: 500,
          title: 'Metadata',
          component: 'EntityUnmappedFields'
        }
      ]
    },
    {
      name: 'automation',
      title: 'Automation',
      feature_flag: 'ff_automation_enabled',
      ui_hooks: [
        {
          hook: 'ActivityPilot:View',
          route: 'automation',
          order: 30,
          import: '@epilot360/automation-capability'
        },
        {
          hook: 'ActivityPilot:QuickAction',
          icon: 'play_circle_filled',
          route: '?activity_pilot=automation',
          order: 30
        }
      ]
    },
    {
      name: 'workflow',
      title: 'Workflow',
      feature_flag: 'ff_workflows_enabled',
      ui_hooks: [
        {
          hook: 'ActivityPilot:View',
          route: 'workflow',
          import: '@epilot360/workflows-sidebar',
          order: 20,
          header: false,
          requiredPermission: {
            action: 'workflow:execution:view'
          }
        },
        {
          hook: 'ActivityPilot:QuickAction',
          icon: 'custom_workflow_icon',
          route: '?activity_pilot=workflow',
          order: 20,
          requiredPermission: {
            action: 'workflow:execution:trigger'
          }
        }
      ]
    },
    {
      name: 'notes',
      title: 'Notes',
      attributes: [
        {
          name: 'notes',
          label: 'Notes',
          type: 'internal'
        }
      ],
      ui_hooks: [
        {
          hook: 'ActivityPilot:QuickAction',
          icon: 'task',
          route: '?drawer=notes',
          order: 50,
          requiredPermission: {
            action: 'note:create'
          }
        },
        {
          hook: 'EntityDetailsV2:Tab',
          title: 'Notes',
          import: '@epilot360/notes',
          order: 52,
          requiredPermission: {
            action: 'note:view'
          }
        }
      ]
    },
    {
      name: 'customer_messaging',
      title: 'Messages',
      feature_flag: 'ff_order_messaging_enabled',
      ui_hooks: [
        {
          hook: 'ActivityPilot:QuickAction',
          icon: 'email',
          route: '?drawer=messaging',
          order: 15,
          requiredPermission: {
            action: 'message:send'
          }
        }
      ]
    },
    {
      name: 'journey_data',
      title: 'Journey Data',
      feature_flag: 'ff_journey_data_capability',
      ui_hooks: [
        {
          hook: 'EntityAttributes:Group',
          title: 'Journey Data',
          import: '@epilot360/journey-data',
          order: 30
        }
      ]
    }
  ],
  draft: false,
  created_at: '2022-05-09T11:56:45.691Z',
  updated_at: '2022-08-31T21:02:49.028Z',
  version: 59,
  source: {
    id: 'e5fee37e-7818-481b-bab1-ae563592d696',
    type: 'blueprint'
  },
  comment: 'Generated patch for entity opportunity, order and price',
  created_by: '10009172',
  explicit_search_mappings: {
    journey_data: {
      type: 'flattened'
    }
  }
}
