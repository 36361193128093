import { BodyText } from '@epilot/base-elements'
import { SnackbarUtils } from '@epilot360/snackbar-service'
import React, {
  ChangeEvent,
  CSSProperties,
  ReactNode,
  useContext,
  useState
} from 'react'
import useUpdateEffect from 'react-use/lib/useUpdateEffect'
import styled from 'styled-components/macro'

import { isConfigMode } from 'utils/StyleUtils'

import PortalConfigContext, {
  ImageKey
} from '../../../context/PortalConfigContext'
import useEcpTranslation from '../../../hooks/common/useEcpTranslation'
import { DEFAULT_IMAGES } from '../../../utils/ConfigEditorUtils'

import { Image } from './Image'
import { ResetTranslation } from './TranslationActionButton'

const useImageOverride = (key: Partial<ImageKey>): string => {
  const { data: portalConfigData } = useContext(PortalConfigContext)

  return portalConfigData.portalConfig.images?.[key] as string
}

interface Props {
  defaultImage?: string | ReactNode
  style?: CSSProperties
  imageKey: Partial<ImageKey>
}

const EditableImage = ({
  defaultImage,
  imageKey,
  style,
  ...restProps
}: Props) => {
  const overrideImage: string = useImageOverride(imageKey)
  const { t } = useEcpTranslation()
  const [src, setSrc] = useState<string | ReactNode>(
    overrideImage || defaultImage
  )
  const { data: portalData, actions: portalConfigActions } =
    useContext(PortalConfigContext)

  useUpdateEffect(() => {
    if (!overrideImage) {
      setSrc(null)
    }
  }, [overrideImage])

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const img = event.target.files[0]

    // File size limit is 200Kb
    const isFileLarge: boolean = img?.size > 200000

    if (!img || isFileLarge) {
      SnackbarUtils.error({
        title: t('common.error.something_went_wrong'),
        message: t('common.error.file_size')
      })

      return
    }

    portalConfigActions.updateImageOverrides(imageKey, img)

    const reader = new FileReader()

    reader.readAsDataURL(img)

    reader.onload = () => {
      const base64Img = reader.result

      setSrc(base64Img)
    }

    reader.onerror = (error) => {
      console.error('Reading file error: ', error)
    }
  }

  const isImageChanged: boolean =
    portalData.originalConfig?.images?.[imageKey] !==
    portalData.portalConfig?.images?.[imageKey]

  const resetToDefaultValue = (): void => {
    const defaultImage = DEFAULT_IMAGES[imageKey]
    const previousImage = portalData.originalConfig.images?.[imageKey]

    setSrc(previousImage || defaultImage)

    portalConfigActions.resetImage(imageKey)
  }

  if (!isConfigMode()) {
    return (
      <ImageWrapper>
        <Image src={src} {...restProps} />
      </ImageWrapper>
    )
  }

  return (
    <Container style={style}>
      <Header>
        <OptionsContainer>
          <TypeText>Image</TypeText>
        </OptionsContainer>

        {isImageChanged && (
          <ResetTranslation context="image" onClick={resetToDefaultValue} />
        )}
      </Header>

      <Content>
        {src && <Image src={src} {...restProps} />}

        {!src && (
          <NoImageTitle>{t('editable_image.no_image_provided')}</NoImageTitle>
        )}
      </Content>

      <ImageInput
        accept="image/png, image/gif, image/jpeg"
        name="myImage"
        onChange={onChange}
        type="file"
      />
    </Container>
  )
}

export default EditableImage

const Header = styled.div`
  opacity: 0;
  position: absolute;
  top: -2.6rem;
  left: 0;
  height: 2.4rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
`

const ImageInput = styled.input`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  cursor: pointer;
  opacity: 0;
`

const Container = styled.div<{ $active?: boolean }>`
  position: relative;
  padding: 1rem;
  margin: -1rem;
  display: flex;
  flex-direction: column;
  border: 0.15rem solid
    ${({ theme, $active }) =>
      $active ? theme.palette.primary[400] : 'transparent'};

  ${Header} {
    opacity: ${({ $active }) => ($active ? 1 : 0)};
  }

  :hover {
    border: 0.15rem solid ${({ theme }) => theme.palette.primary[400]};

    ${Header} {
      opacity: 1;
    }
  }
`

const OptionsContainer = styled.div`
  background: #ffffff;
  box-shadow: 0 0.1rem 0.4rem rgba(21, 43, 66, 0.16);
  border-top-left-radius: 0.4rem;
  border-top-right-radius: 0.4rem;
  padding: 0 0.8rem;
  height: 2.4rem;
  display: flex;
  align-items: center;
`

const TypeText = styled(BodyText)`
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
`

const Content = styled.div`
  display: flex;
  cursor: pointer;
  width: 100%;
  height: 100%;

  > * {
    width: 100%;
  }

  img {
    object-fit: cover;
  }
`

const NoImageTitle = styled(BodyText)`
  display: flex;
  justify-content: center;
  align-items: center;
`

const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  text-align: center;

  img {
    object-fit: cover;
  }
`
