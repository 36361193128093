import React, { ReactNode } from 'react'

interface Props {
  src: string | ReactNode
}

const Image = ({ src, ...restProps }: Props) => {
  if (typeof src === 'string' || src instanceof String) {
    return <img alt="img" src={src as string} {...restProps} />
  }

  return <>{src}</>
}

export { Image }
