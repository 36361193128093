import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import { Locale } from '../utils/LanguageUtils'

import de from './locales/de.json'
import en from './locales/en.json'

const LANGUAGE = 'language'

const initialLanguage = localStorage.getItem(LANGUAGE) || 'de'

export const changeLanguage = (language: Locale) => {
  i18n.changeLanguage(language)
  localStorage.setItem(LANGUAGE, language as string)
}

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translations: en
    },
    de: {
      translations: de
    }
  },
  ns: ['translations'],
  defaultNS: 'translations',
  fallbackLng: 'de',
  lng: initialLanguage,
  nsSeparator: '::'
})

i18n.languages = ['en', 'de']

// update <html lang="en"> attribute
i18n.on('languageChanged', (lng) => {
  document.documentElement.lang = lng
})

export { LANGUAGE, i18n }
